import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"35%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Kiosk Information ")]),_c(VDivider),_c(VCardText,[_c(VList,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-monitor")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.kioskObject.data().name)+" ")]),_c(VListItemSubtitle,[_vm._v("Kiosk Name & Avatar Name")])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-map-marker")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.kioskObject.data().location)+" ")]),_c(VListItemSubtitle,[_vm._v("Kiosk Location")])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-login-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.kioskObject.data().email)+" ")]),_c(VListItemSubtitle,[_vm._v("Kiosk Login Email")])],1),(
            ['Admin', 'Super Admin'].includes(_vm.userProfile.data().userRole)
          )?_c('v-list-item-actions',[(!_vm.showPassword)?_c(VBtn,{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){_vm.showPassword = true}}},[_vm._v("Password")]):_c('span',{staticClass:"primary--text text-h6"},[_vm._v("backspace")])],1):_vm._e()],1)],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"outlined":"","width":"175","color":"error"},on:{"click":function($event){_vm.show = false;
      _vm.showPassword = false;}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Close ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }