import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[(!_vm.appData.configured)?_c(VAlert,{staticClass:"white--text my-3 text-subtitle-1 font-weight-bold",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(this.error)+" "),_c(VBtn,{staticClass:"ml-16",on:{"click":function($event){return _vm.$router.push('/configureClient')}}},[_vm._v(" Configure Client ")])],1):_vm._e(),_c(VCard,{staticClass:"px-3",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h4 grey--text text--darken-3"},[_vm._v(" Kiosk Management "),_c(VSpacer),(_vm.isSuperAdmin)?_c(VBtn,{attrs:{"elevation":"0","color":"primary","disabled":!_vm.appData.configured},on:{"click":function($event){return _vm.openDialog()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add New Kiosk ")],1):_vm._e()],1)],1),_c(VCard,{staticClass:"px-3 mt-3",attrs:{"elevation":"0"}},[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("Registered Kiosks")]),(_vm.isSuperAdmin)?_c(VTab,[_vm._v("Invited Kiosks")]):_vm._e(),_c(VSpacer)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('registeredKioskList')],1),_c(VTabItem,[_c('invitedKioskList')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }