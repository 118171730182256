<template>
  <v-data-table
    :loading="loading"
    :headers="invitedKiosksHeaders"
    :items="allInvitedKiosks"
  >
    <template v-slot:item.name="{ item }">
      {{ item.data().name }}
    </template>
    <template v-slot:item.location="{ item }">
      {{ item.data().location }}
    </template>
    <template v-slot:item.email="{ item }">
      {{ item.data().email }}
    </template>
    <template v-slot:item.createdOn="{ item }">
      {{ item.data().createdOn | formatDate }}
    </template>
    <template v-slot:item.actions="{ item }">
      <editAndDeleteKiosk kioskType="invited" :kioskIndex="item">
      </editAndDeleteKiosk>
    </template>
    <template v-slot:item.inviteLink="{ item }">
      <v-btn small color="primary" text @click="copyLink(item)">
        <v-icon left small>mdi-content-copy</v-icon>
        Copy Link
      </v-btn>
      <input
        type="hidden"
        :id="'user' + item.id"
        :value="registrationLink + item.id"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteKiosk from "@/components/dialogBox/kiosks/editAndDeleteKiosk.vue";
export default {
  name: "invitedKioskList",
  components: {
    editAndDeleteKiosk,
  },
  data() {
    return {
      loading: true,
      registrationLink: window.location.hostname + "/kioskRegistration/",
      invitedKiosksHeaders: [
        { text: "Name", align: "start", value: "name" },
        { text: "Location", value: "location" },
        { text: "Email", value: "email" },
        { text: "Invited On", value: "createdOn" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "Invitation Link", value: "inviteLink", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["allInvitedKiosks"]),
  },
  methods: {
    copyLink(kiosk) {
      let link = document.querySelector("#user" + kiosk.id);
      link.setAttribute("type", "text");
      link.select();

      if (document.execCommand("copy")) {
        this.$store.dispatch("showSnackBar", {
          text: "Invitation link copied successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnackBar", {
          text: "Link could not be copied.",
          color: "error",
        });
      }
      link.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  mounted() {
    this.$store.dispatch("getAllInvitedKiosks").then(
      () => {
        this.loading = false;
      },
      (error) => {
        this.$store.dispatch("showSnackBar", {
          text: error,
          color: "error",
          timeout: 0,
        });
      }
    );
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>