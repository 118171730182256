<template>
  <v-container fluid>
    <v-alert v-if="!appData.configured" class="white--text my-3 text-subtitle-1 font-weight-bold" color="error"> {{
      this.error }}
      <v-btn class="ml-16" @click="$router.push('/configureClient')"> Configure Client </v-btn>
    </v-alert>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4 grey--text text--darken-3">
        Kiosk Management
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" @click="openDialog()" :disabled="!appData.configured" v-if="isSuperAdmin">
          <v-icon left>
            mdi-plus
          </v-icon>
          Add New Kiosk
        </v-btn>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">
      <v-tabs v-model="tab">
        <v-tab>Registered Kiosks</v-tab>
        <v-tab v-if="isSuperAdmin">Invited Kiosks</v-tab>
        <v-spacer></v-spacer>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <registeredKioskList></registeredKioskList>
        </v-tab-item>
        <v-tab-item>
          <invitedKioskList></invitedKioskList>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import addNewKiosk from "@/components/dialogBox/kiosks/addNewKiosk.vue";
import registeredKioskList from "@/components/kioskManagement/registeredKioskList.vue";
import invitedKioskList from "@/components/kioskManagement/invitedKioskList.vue";
import { mapGetters } from 'vuex';

export default {
  name: "kioskManagement",
  components: {
    addNewKiosk,
    registeredKioskList,
    invitedKioskList,
  },
  data() {
    return {
      tab: null,
      error: 'Please configure the Client first. Click on the Configure Client button.'
    };
  },
  computed: {
    ...mapGetters(['userProfile', 'appData', 'isSuperAdmin'])
  },
  methods: {
    openDialog() {
      if (this.appData.configured) {
        this.$store.commit('setKioskDialog', true)
      } else {
        this.error = 'Please configure the Client first. Click on the Configure Client option from Navigation window on the left.'
      }
    }
  }
};
</script>