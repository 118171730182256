<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }" v-if="isSuperAdmin">
      <v-icon v-bind="attrs" v-on="on" small @click="editKiosk">
        mdi-pencil
      </v-icon>
      <v-icon v-bind="attrs" v-on="on" small class="ml-2" @click="deleteKiosk">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <!-- <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div> -->
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editKioskForm" lazy-validation class="px-6">
          <v-text-field v-model="kiosk.name" label="Kiosk's Name" :rules="rules.nameRules" required>
          </v-text-field>
          <v-text-field v-model="kiosk.location" label="Location" :rules="rules.locationRules" required>
          </v-text-field>
          <v-text-field v-model="kiosk.email" label="E-mail" :rules="rules.emailRules" required disabled>
          </v-text-field>
          <v-textarea outlined label="Description" v-model="kiosk.description">
          </v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined width="175" @click="show = !show">
              <v-icon left>mdi-close</v-icon>
              Close
            </v-btn>
            <v-btn color="primary" width="175" @click="updateKiosk">
              <v-icon left>mdi-content-save-cog</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn color="error" @click="show = !show" outlined width="175"><v-icon left>mdi-close</v-icon>Close</v-btn>
        <v-btn color="primary" @click="confirmDeleteKiosk" width="175"><v-icon left>mdi-delete</v-icon>Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'editAndDeleteUser',
  props: {
    kioskIndex: Object,
    kioskType: String
  },
  data() {
    return {
      show: null,
      actionType: '',
      formTitle: '',
      loading: false,
      kiosk: {
        name: '',
        email: '',
        location: '',
        description: '',
        id: null,
      },
      rules: {
        nameRules: [
          v => !!v || 'Kiosk name is required',
        ],
        locationRules: [
          v => !!v || 'Location name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    }
  },
  methods: {
    editKiosk() {
      this.formTitle = "Edit Kiosk";
      this.actionType = 'edit';
      this.kiosk = Object.assign({}, this.kioskIndex.data());
      this.kiosk.id = this.kioskIndex.id;
    },
    deleteKiosk() {
      this.formTitle = "Are you sure you want to delete this kiosk?";
      this.actionType = 'delete';
    },
    updateKiosk() {
      if (this.$refs.editKioskForm.validate()) {
        this.loading = true;
        if (this.kioskType === 'registered') {
          this.$store.dispatch('updateRegisteredKioskInformation', this.kiosk)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
        if (this.kioskType === 'invited') {
          this.$store.dispatch('updateInvitedKioskInformation', this.kiosk)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }

      }
    },
    confirmDeleteKiosk() {
      this.loading = true;
      if (this.kioskType === 'registered') {
        this.$store.dispatch('deleteRegisteredKiosk', this.kioskIndex.id)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
      if (this.kioskType === 'invited') {
        this.$store.dispatch('deleteInvitedKiosk', this.kioskIndex.id)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    }

  },
  computed: {
    ...mapGetters(['allRegisteredKiosk', 'userProfile', 'isSuperAdmin']),
  },
  mounted() {
    //
  }
}
</script>